import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeImage from '../components/Images/HomeImage';
import logoWhite from '../images/logo-white.svg';



const HistoirePage = () => (
  <Layout>
    <SEO title="Histoire" />

    <div className="content page">
      <div style={{backgroundColor: '#6FA287', display: 'flex', padding: '2rem' }}>
        <img src={logoWhite} alt="logo" className="banner-logo" style={{ width: '100%', maxWidth: 300, margin: '0 auto'}}/>
      </div>

      <h1>Histoire</h1>
      <div className="home-paragraph">
        <div>
          <p>
            La marque Lié(e) est née d'une grande envie de la créatrice Chloé Hirsch. <br/> <br/>
            Après des études théâtrales, Chloé Hirsch a longuement travaillé dans l’univers de la mode comme attachée de presse.
            Ses passions: la danse, la comédie, la mode et le bijou dans ce qu’il a de plus symbolique.
            Tout ce que porte Chloé possède une histoire, détient un sens – caché ou non.
            C’est ce qu’elle recherche avec Lié(e): l’histoire, l’essentiel, la simplicité. Et donc l’émotion.
          </p>
        </div>
      </div>
    </div>
    <div className="home-2-images">
      <div>
        <HomeImage image="chloe" />
      </div>
    </div>
  </Layout>
);

export default HistoirePage;
